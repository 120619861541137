// ********************************
// COPYRIGHT OF PRIME PRESENCE 2024
// ********************************

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronRight, faUser} from '@fortawesome/free-solid-svg-icons';
import './JrrButton.css';

// Represents the rounded button component
function JrrButton(props)
{
    // Props that may be used to customize button
    const {id, label, icon, className, onclick} = props;

    // Function returns icon based upon string in 'icon' prop ( default is right facing chevron )
    const getIcon = () => {
        if(icon === "login")
        {
            return (
                <FontAwesomeIcon icon={faUser} className="jrr-button-icon"/>
            );
        }
        else
        {
            return (
                <FontAwesomeIcon icon={faChevronRight} className="jrr-button-icon"/>
            );
        }
    }

    return (
        <div id={id} onClick={onclick} className={className}>
            <p id="jrr-button-label">{label}</p>
            {getIcon()}
        </div>
    )
}

export default JrrButton;
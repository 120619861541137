// ********************************
// COPYRIGHT OF PRIME PRESENCE 2024
// ********************************

import text from '../BusinessInfo.csv';

export async function ReadFile(setBusinessInfo) {
    var businessInfo = {}
    var useDefaults = false;
    fetch(text)
    .then(r => r.text())
    .then(text => {

        // Manual Additions
        businessInfo["logoDarkPath"] = process.env.PUBLIC_URL + 'Images/logo-dark.png';
        businessInfo["logoLightPath"] = process.env.PUBLIC_URL + 'Images/logo-light.png';
        businessInfo["bodyImagePath"] = process.env.PUBLIC_URL + 'Images/body-image.jpg';
        businessInfo["getStartedImagePath"] = process.env.PUBLIC_URL + "Images/multicircles.webp";
        businessInfo["aboutImagePath"] = process.env.PUBLIC_URL + "Images/about-image.jpg";
        businessInfo["missionImagePath"] = process.env.PUBLIC_URL + "Images/mission-image.jpg";
        businessInfo["copyrightText"] = "© 2024 JRR CONCEPTS | ";
        businessInfo["copyrightImportant"] = "CREATED BY PRIME PRESENCE";

        if(!text) {
            console.error("Text in BusinessInfo.csv is undefined! Using default values. **To fix replace current BusinessInfo.csv with a csv with the valid format**");
            setBusinessInfo(businessInfo);
            useDefaults = true;
        }


        var splitInfo = text.split('"');
        var count = 0;
        var columns = ["date", "ownerName", "email", "address", "phoneNumber", "businessName", "domain", "primaryColor", "secondaryColor", "heroText", "aboutText", "missionText", "logo"]
        if(splitInfo.length < columns.length * 2 && !useDefaults) {
            console.error("Text in BusinessInfo.csv is not in the correct format! Using default values. **To fix replace current BusinessInfo.csv with a csv with the valid format**");
            useDefaults = true;
        }

        // Error with text and set default values
        if(useDefaults) {
            setDefaults(businessInfo);
            setBusinessInfo(businessInfo);
            return;
        }

        for(var info of splitInfo) {
            if(count >= columns.length) {
                if(info.startsWith(',')) {
                    // Remove starting and ending comma
                    info = info.replace(/^,/, '');
                    info = info.replace(/,$/, '');
                }
                
                if (info !== "" && !info.startsWith("\n") && !info.startsWith("\r")){
                    // console.log(count, columns[count - columns.length], info);
                    switch(columns[count - columns.length]) {
                        case "date":
                            //Date
                            break;
                        case "ownerName":
                            // Person Name
                            businessInfo["ownerName"] = info;
                            break;
                        case "email":
                            // Email
                            businessInfo["email"] = info;
                            break;
                        case "address":
                            // Address
                            businessInfo["address"] = info;
                            break;
                        case "phoneNumber":
                            // Phone number
                            businessInfo["phoneNumber"] = info;
                            break;
                        case "businessName":
                            // Business Name
                            businessInfo["businessName"] = info;
                            break;
                        case "domain":
                            // Domain
                            break;
                        case "primaryColor":
                            // Primary color
                            businessInfo["primaryColor"] = info;
                            break;
                        case "secondaryColor":
                            // Secondary Color
                            businessInfo["secondaryColor"] = info;
                            break;
                        case "heroText":
                            // Hero text
                            var tempSplit = info.split(',');
                            var infoImportant = tempSplit[0] + "," + tempSplit[1] + ",";
                            var infoBasic = "";
                            for(let i = 2; i < tempSplit.length; i++) {
                                if(i !== tempSplit.length - 1) {
                                    infoBasic += tempSplit[i] + ",";
                                }
                                else {
                                    infoBasic += tempSplit[i];
                                }
                            }
                            businessInfo["heroTextImportant"] = infoImportant;
                            businessInfo["heroText"] = infoBasic;
                            break;
                        case "aboutText":
                            // About text
                            var paragraphs = [];
                            tempSplit = info.split("\n");
                            for(var paragraph of tempSplit) {
                                paragraphs.push(paragraph);
                            }
                            businessInfo["aboutText"] = paragraphs;

                            break;
                        case "missionText":
                            // Mission statment
                            paragraphs = [];
                            tempSplit = info.split("\n");
                            for(paragraph of tempSplit) {
                                paragraphs.push(paragraph);
                            }
                            businessInfo["missionText"] = paragraphs;
                            break;
                        case "logo":
                            // Logo url
                            break;
                        default:
                            break;
                    }
                    count += 1;
                }
            }
            // Move through the names of the columnss
            else if(info !== "" && info !== ",") {
                count += 1;
            }
        }
        
       
        businessInfo["defaultLightLogo"] = makeDefaultLogo(businessInfo, "light-logo");
        businessInfo["defaultDarkLogo"] = makeDefaultLogo(businessInfo, "dark-logo");

        setBusinessInfo(businessInfo);
       
    }); 
}

const setDefaults = (businessInfo) => {
    businessInfo["ownerName"] = "John Doe";
    businessInfo["email"] = "example@example.com";
    businessInfo["address"] = "123 Main Street, City, Country";
    businessInfo["phoneNumber"] = "123-456-7890";
    businessInfo["businessName"] = "ABC Company";
    businessInfo["primaryColor"] = "#17176B";
    businessInfo["secondaryColor"] = "#FE4F00";
    businessInfo["heroTextImportant"] = "Empowering Your Business Journey - At our consulting firm, we're committed to assisting new and growing businesses in navigating the complexities of operations and finance,";
    businessInfo["heroText"] = " paving the way for a smoother journey to success. Our team's expertise serves as your guide in the dynamic realm of entrepreneurship. Let's embark on this journey together, transforming challenges into opportunities for your business's advancement.";

    var aboutParagraphs = []
    aboutParagraphs.push("In our journey, the organization has achieved numerous milestones and garnered accolades, a testament to our unwavering commitment to excellence. Our expertise lies in understanding customer needs and delivering innovative solutions to meet them, ensuring maximum satisfaction and success.");
    aboutParagraphs.push("Driven by a shared sense of purpose, the team at our organization aspires to make a positive impact on society, continuously evolving as individuals and leaders, and upholding values that prioritize integrity, collaboration, resilience, and lifelong learning. Our goals are aligned with our mission to empower customers and contribute to their success, while fostering a culture of innovation and growth.");
    aboutParagraphs.push("Moreover, we are dedicated to giving back to the community through various philanthropic initiatives, such as supporting local charities and environmental conservation efforts. We embody a spirit of service, empathy, and sustainability, striving to create a better world for future generations.");

    businessInfo["aboutText"] = aboutParagraphs;

    
    businessInfo["missionText"] = ["At our consultancy, our mission is to offer unparalleled consulting services that empower businesses to overcome obstacles in operations, finance, and beyond. With a proven track record of guiding companies through their formative challenges, we are committed to providing strategic insights and actionable solutions that are tailored to the unique needs of each client. Our approach is rooted in a deep-seated belief in the transformative power of personalized consultancy. We strive to be more than consultants; we aim to be the catalysts for sustained success, fostering resilience and innovation in businesses poised for greatness."];

    businessInfo["defaultLightLogo"] = makeDefaultLogo(businessInfo, "light-logo");
    businessInfo["defaultDarkLogo"] = makeDefaultLogo(businessInfo, "dark-logo");
}

const makeDefaultLogo = (businessInfo, logoType) => {
    var splitBusinessName = businessInfo["businessName"].split(" ");

    var topWord = splitBusinessName[0];
    var bottomWords = "";
    for(var i = 1; i < splitBusinessName.length; i++) {
        if(i === splitBusinessName.length - 1) {
            bottomWords = bottomWords + splitBusinessName[i];
        }
        else {
            bottomWords =  bottomWords + splitBusinessName[i] + " ";
        }
    }

    return <div className='words-logo-wrapper'>
        <h2 id={logoType + "-top-word"} className='words-logo'>{topWord}</h2>
        <h2 id={logoType + "-bottom-word"} className='words-logo'>{bottomWords}</h2>
    </div>
}
// ********************************
// COPYRIGHT OF PRIME PRESENCE 2024
// ********************************

import React, {useEffect} from 'react';
import './Sidebar.css'; // Import CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

const Sidebar = ({ isOpen, toggle, goToAbout, gotToContact }) => {
    // Method to be used by sidebar links
    const handleGoToAbout = () => {
        // Toggles the sidebar
        toggle();
        // Scrolls page to the About Section
        goToAbout();
    }

    // Method to be used by sidebar links
    const handleGoToContact = () => {
        // Toggles the sidebar
        toggle();
        // Scrolls page to Contact Form
        gotToContact();
    }

    useEffect(() => {
        // Add or remove 'noscroll' class based on the isOpen state
        // 'noscroll' locks srcolling for the app
        const html = document.documentElement;
        if (isOpen) {
          html.classList.add('noscroll');
        } else {
          html.classList.remove('noscroll');
        }
      }, [isOpen]);
    
    // Handles close button 'X' fade in/out on sidebar open/close
    const buttonStyle = {
        color: isOpen ? 'var(--primarycolor)' : 'white', // Adjust the right margin based on sidebar state
        transition: 'color 0.5s', // Ensure smooth transition
      };
    
    return (
        <div>
            {/* Dark Overlay for page behind sidebar when side bar is open */}
            <div className={isOpen ? 'overlay' : ''} onClick={toggle}></div>
            <div className={isOpen ? 'sidebar open' : 'sidebar'}>
                <FontAwesomeIcon icon={faX} id="sidebar-button-close" style={buttonStyle} onClick={toggle} />
                <ul>
                    <li onClick={toggle}>Home</li>
                    <li onClick={handleGoToAbout}>About</li>
                    <li onClick={handleGoToContact}>Contact</li>
                    <li>Login</li>
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
// ********************************
// COPYRIGHT OF PRIME PRESENCE 2024
// ********************************
export default async function HandleSubmit(formName, formPhone, formEmail, formMessage, formHoneyPot, setFormName, setFormPhone, setFormEmail, setFormMessage, setIsSubmitting, setFormError, setFormSubmitted, setFormHoneyPot, businessEmail) {
    setFormSubmitted(false);
    setIsSubmitting(true);
    setFormError("");

    if(formHoneyPot !== "") {
        setFormSubmitted(true);
        setIsSubmitting(false);
        setFormName("");
        setFormPhone("");
        setFormEmail("");
        setFormMessage("");
        return
    }

    if(!CleanInputs(formName, formPhone, formEmail, formMessage, setFormError)) {
        setIsSubmitting(false);
        setFormSubmitted(false);
        return;
    }
    try {

        // Make API request
        const response = await fetch("http://3.130.145.179:3000/send-email", {
          method: 'POST',
          headers: {
            'x-api-key': '1415a2c4-2682-4e86-b886-9fcae7e42207',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: businessEmail, formName: formName, formPhone: formPhone, formEmail: formEmail, formMessage: formMessage  }) // Include form data in the request body
        });
        
        // Optionally handle the API response
        if(response.status === 200) {
            setFormSubmitted(true);
            // Reset form fields
            setFormName("");
            setFormPhone("");
            setFormEmail("");
            setFormMessage("");

        }
        else if (response.status === 429) {
            setFormError("Failed to submit contact form. Too many requests. Try again later.")
        }
        else {
            setFormError("Error submitting form.")
        }
        
        // Optionally perform any other actions after successful API request
  
      } catch (error) {
        // Handle API request error
        console.error('API request failed:', error.message);
        setFormError("Failed to submit contact form.")
      }
  
      setIsSubmitting(false); // Set isSubmitting back to false after the API request completes
}

function CleanInputs(formName, formPhone, formEmail, formMessage, setFormError) {
    const emailPattern = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
    const phonePattern = /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/;
    const namePattern = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
    const messagePattern = /^[^\r\n<>\\{}]*$/;
    var isError = false;
    var errorMessage = "Please input valid: ";
    var errors = [];

    if(!namePattern.test(formName)) {
        isError = true;
        errors.push("Name");
    }

    if(!emailPattern.test(formEmail)) {
        isError = true;
        errors.push("Email");
    }

    if(!phonePattern.test(formPhone)) {
        isError = true;
        errors.push("Phone Number");
    }

    if(!messagePattern.test(formMessage)) {
        isError = true;
        errors.push("Message");
    }

    for(var i = 0; i < errors.length; i++) {
        if(i === errors.length-1) {
            errorMessage += "and " + errors[i];
        }
        else {
            errorMessage += errors[i] + ", "
        }
    }

    if(isError) {
        errorMessage += ". Make sure no special characters are used such as <, >, \, {, }, carriage return, and newline."
        setFormError(errorMessage);
        return false;
    }
    return true;
}
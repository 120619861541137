// ********************************
// COPYRIGHT OF PRIME PRESENCE 2024
// ********************************

import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocationDot, faBars} from '@fortawesome/free-solid-svg-icons';
import JrrButton from './Components/JrrButton';
import JrrJiggleLink from './Components/JrrJiggleLink';
import { useEffect, useRef, useState } from 'react';
import useWindowSize from './useWindowSize';
import Sidebar from './Components/Sidebar';
import { ReadFile } from './Components/InfoReader';
import HandleSubmit from './Components/FormHandler';


function App() {

  // Reference used to locate the 'about' section of the page
  const aboutRef = useRef(null);
  // Reference used to locate the 'contact form' section of the page
  const contactRef = useRef(null);
  // Finds current screen size
  const windowSize = useWindowSize();
  // Holds state of sidebar
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const root = document.documentElement;

  const [darkLogoError, setDarkLogoError] = useState(false);
  const [lightLogoError, setLightLogoError] = useState(false);

  // Contact form values
  const [formName, setFormName] = useState("");
  const [formHoneyPot, setFormHoneyPot] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formPhone, setFormPhone] = useState("");
  const [formMessage, setFormMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState("")
  const [formSubmitted, setFormSubmitted] = useState(false);



  //#region BUSINESS INFO
  const [businessInfo, setBusinessInfo] = useState({});

  // Read Business info file
  useEffect(() => {
    ReadFile(setBusinessInfo)
  }, []);

  // Update site with new info
  useEffect(() => {
    if(isColor(businessInfo["primaryColor"]) && isColor(businessInfo["secondaryColor"])) {
      root.style.setProperty('--primarycolor', businessInfo["primaryColor"]);
      root.style.setProperty('--secondarycolor', businessInfo["secondaryColor"]);
    }

    document.title = businessInfo["businessName"];

    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute('content',  businessInfo["businessName"] + " Company Website");
    }
  }, [businessInfo])

  const isColor = (strColor) => {
    const s = new Option().style;
    s.color = strColor;
    return s.color !== '';
  }
  //#endregion

  // Function that switches the current state of the sidebar
  const toggleSidebar = () => {
    setIsSideBarOpen(!isSideBarOpen);
  };

  // Function handles scrolling page to 'about' reference
  const handleGoToAbout = () => {
    aboutRef.current?.scrollIntoView({ behavior:'smooth'})
  }

  // Function handles scrolling page to 'contact form' reference
  const handleGoToContact = () => {
    contactRef.current?.scrollIntoView({ behavior:'smooth'})
  }

  // Handles moving header links into sidebar on small window width
  const handleMobileLinks = () => {
      // Links moved to sidebar if screen size is less than or equal to 850px for mobile
      if(windowSize.width <= 850)
      {
        return (
          <FontAwesomeIcon icon={faBars} className="menu-icon" onClick={toggleSidebar}/>
        )
      }
      else
      {
        return (
          <div className="header-links-wrapper">
            <div id="home-link" className="header-link-wrapper">
              <p  className="header-link">Home</p>
            </div>
            <div  className="header-link-wrapper">
              <p onClick={handleGoToAbout} className="header-link">About</p>
            </div>
            <div id="navlink" className="header-link-wrapper">
              <p onClick={handleGoToContact} className="header-link">Contact</p>
            </div>
            <JrrButton icon="login" label="Login" className="jrr-orange-button"/>
          </div>
        )
      }
  }

  const handleAboutText = () => {
    
      if("aboutText" in businessInfo) {
        return businessInfo["aboutText"].map((paragraph, index) => (
          <p className="about-content-paragraph" key={index}>{paragraph}</p>
        ));
      }
      else {
        return <p className="about-content-paragraph"></p>;
      }
  }

  const handleMissionText = () => {
    
    if("missionText" in businessInfo) {
      return businessInfo["missionText"].map((paragraph, index) => (
        <p className="mission-content-paragraph" key={index}>{paragraph}</p>
      ));
    }
    else {
      return <p className="mission-content-paragraph"></p>;
    }
  }

  const handleAboutSection = () => {

    if(windowSize.width <= 850) {
      //Mobile size
      return (
        <div id="about" ref={aboutRef} className="about-container content-container">
          <div className="about-img-container">
            <div className="square-image-crop">
              <img id="about-img" src={businessInfo["aboutImagePath"]} alt="Happy employee" />
            </div>
          </div>
          <div className="about-content">
            <h2 className="about-title">About <span className='no-bold'>Us</span></h2>
            <div className='paragraphs-wrapper' >
              {handleAboutText()}
            </div>
            
            <JrrJiggleLink id="about-jiggle-link" icon="" label="LET'S WORK TOGETHER" className="" onclick={handleGoToContact}/>
          </div>
        </div>
      )
    }
    else {
      return (
      <div id="about" ref={aboutRef} className="about-container content-container">
          <div className="about-content">
              <h2 className="about-title">About <span className='no-bold'>Us</span></h2>
              <div className='paragraphs-wrapper' >
                {handleAboutText()}
              </div>
              
              <JrrJiggleLink id="about-jiggle-link" icon="" label="LET'S WORK TOGETHER" className="" onclick={handleGoToContact}/>
          </div>
          <div className="about-img-container">
            <div className="square-image-crop">
              <img id="about-img" src={businessInfo["aboutImagePath"]} alt="Happy employee" />
            </div>
          </div>
        </div>
      )
    }
  }
  
  return (
    <div>
      {/* Sidebar component (only able to be shown on small window width) */}
      <Sidebar isOpen={isSideBarOpen} toggle={toggleSidebar} goToAbout={handleGoToAbout} gotToContact={handleGoToContact} />
      <div className="page-container">
        {/* Holds contact Us with phone number */}
        <header className="header-container">
          <div className="icon-text-wrapper">
            <FontAwesomeIcon icon={faPhone} className="secondary-icon"/>
            <p className="icon-text">{"Call us! " + businessInfo["phoneNumber"]}</p>
            
          </div>
        </header>
        <div id="body-background" className="white-background-container">
          <div className="body-container">
            {/* Holds logo and page links */}
            <div className="content-container">
              <div className="body-header">
                <div className="logo-crop">
                  {!darkLogoError ? (
                    <img src={businessInfo["logoDarkPath"]} alt={businessInfo["businessName"]} className="logo-img" onError={() => setDarkLogoError(true)} />
                  ) : (
                    businessInfo["defaultDarkLogo"]
                  )}
                </div>
                {handleMobileLinks()}
              </div>
            </div>

            {/* Business name and body image with tag line */}
            <div className="spacer">
              <div className="body-content content-container">
                <div className="body-description">
                  <h2 id="welcome-text">Welcome to</h2>
                  <h1 className="big-header">{businessInfo["businessName"]}</h1>
                  <p id="tag-line">We propel small businesses to success with cutting-edge ideas and transformative strategies.</p>
                  <div className="button-with-image-wrapper">
                    <JrrButton id="get-started" icon="getstarted" label="Get Started" className="jrr-orange-button" onclick={handleGoToContact}/>
                    <img src={businessInfo["getStartedImagePath"]} alt="Peoples's Faces" className="multi-faces-img"/>
                  </div>
                </div>
                <div className="img-wrapper-container">
                  <img src={businessInfo["bodyImagePath"]} alt="Smiling Customer" className="body-img"/>
                </div>
              </div>
            </div>
            {/* Holds the longer description, Learn More link, and inspiration block */}
            <div id="body-footer-spacer" className="spacer">
              <div className="body-footer content-container">
                <div className="body-footer-description">
                  <p className='body-description-text'><b>{businessInfo["heroTextImportant"]}</b>{businessInfo["heroText"]}</p>
                  <JrrJiggleLink icon="" label="LEARN MORE" className="" onclick={handleGoToAbout}/>
                </div>
                <div className="inspiration-slides">
                  <h3 className='inspiration-static'>We are</h3>
                  <h3 className='inspiration-changing'></h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Holds About section */}
        <div id="about-background" className="white-background-container">
          {handleAboutSection()}
        </div>

        {/* Holds Mission section */}
        <div id="mission-background">
          <div id="mission" className="mission-container content-container">
            <div className="mission-img-container">
              <div className="square-image-crop">
                <img id="mission-img" src={businessInfo["missionImagePath"]} alt="Man smiling" />
              </div>
            </div>
            <div className="mission-content">
                <h2 className="mission-title">Our <span className='no-bold'>Mission</span></h2>
                <div className='paragraphs-wrapper' >
                  {handleMissionText()}
                </div>
                <JrrJiggleLink id="mission-jiggle-link" icon="" label="CONNNECT WITH US" className="" onclick={handleGoToContact}/>
            </div>
          </div>
        </div>
        
        {/* Holds Contact Form */}
        <div id="contact-form" className="contact-section-container" ref={contactRef}>
          <div className="contact-form-container">
            <h1 className="contact-title"><span className='no-bold'>GET</span> IN TOUCH</h1>
            <h3 className="contact-subtitle">Ready to take the next step?<br/>Contact us today for personalized assistance.</h3>
              <form action="" onSubmit={(e) => { e.preventDefault(); HandleSubmit(formName, formPhone, formEmail, formMessage, formHoneyPot, setFormName, setFormPhone, setFormEmail, setFormMessage, setIsSubmitting, setFormError, setFormSubmitted, setFormHoneyPot, businessInfo["email"])} } className="contact-form">
                <div className="contact-label-input-wrapper">
                  <label htmlFor="name" className="contact-form-label">Name</label>
                  <input placeholder="Your Name" type="text" id="name" name="name" value={formName} onChange={(e) => setFormName(e.target.value)} className="contact-form-input"/>
                </div>
                <div className="contact-label-input-wrapper">
                  <label htmlFor="email" className="contact-form-label">Email</label>
                  <input placeholder="Your Email" type="text" id="email" name="email" value={formEmail} onChange={(e) => setFormEmail(e.target.value)} className="contact-form-input"/>
                </div>
                <div className="contact-label-input-wrapper">
                  <label htmlFor="phonenumber" className="contact-form-label">Phone</label>
                  <input placeholder="Your Phone Number" type="text" id="phonenumber" name="phonenumber" value={formPhone} onChange={(e) => setFormPhone(e.target.value)} className="contact-form-input"/>
                </div>
                <div className="contact-label-input-wrapper">
                  <label htmlFor="message" className="contact-form-label">Message</label>
                  <textarea type="text" id="message" name="message" value={formMessage} onChange={(e) => setFormMessage(e.target.value)} className="contact-form-input" rows="4" cols="50"/>
                </div>
                <div className="contact-label-input-wrapper">
                  <label htmlFor="extra" className="contact-form-label" id="extra-label">Extra</label>
                  <input placeholder="" type="text" id="extra-input" name="extra" value={formHoneyPot} onChange={(e) => setFormHoneyPot(e.target.value)} className="contact-form-input"/>
                </div>
                {formError !== "" && (<p id="form-error" >{formError}</p>)}
                <button 
                  type="submit"   
                  disabled={isSubmitting} 
                  className="contact-submit">
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </button>
                  {formSubmitted && (<p id="form-submitted" >Submitted ✓</p>)}
              </form>
          </div>
        </div>
        
        {/* Holds Footer */}
        <footer className="footer-container">
          <div className='footer-body-spacer'>
            <div className="footer-body">
              <div className="footer-contact">
                <h2 className='footer-contact-title'>Contact Info</h2>
                <div className="contact-line">
                  <FontAwesomeIcon icon={faPhone} className="contact-icon"/>
                  <p className="contact-line-text">{businessInfo["phoneNumber"]}</p>
                </div>
                <div className="contact-line">
                  <FontAwesomeIcon icon={faEnvelope} className="contact-icon"/>
                  <p className="contact-line-text">{businessInfo["email"]}</p>
                </div>
                <div className="contact-line">
                  <FontAwesomeIcon icon={faLocationDot} className="contact-icon"/>
                  <p className="contact-line-text">{businessInfo["address"]}</p>
                </div>
              </div>
              <div className="logo-crop">
                {!lightLogoError ? (
                  <img src={businessInfo["logoLightPath"]} alt={businessInfo["businessName"]} className="logo-img" onError={() => setLightLogoError(true)} />
                ) : (
                  businessInfo["defaultLightLogo"]
                )}
              </div>
            </div>
          </div>
          <div className="footer-footer">
            <p className="copyright">{businessInfo["copyrightText"]}<b>{businessInfo["copyrightImportant"]}</b></p>
          </div>
        </footer>
      </div>
    </div>

  );
}

export default App;

// ********************************
// COPYRIGHT OF PRIME PRESENCE 2024
// ********************************

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import "./JrrJiggleLink.css";

// Represents link with icon that moves when hovered
function JrrJiggleLink(props)
{   
    // Props that may be used to customize link
    var {id, label, icon, className, onclick} = props;

    // sets icon to "default" if nothing is passed in
    if(icon === "")
    {
        icon = "default";
    }

    // sets className to "jrr-jiggle-link-default" if nothing is passed in
    if(className === "")
    {
        className = "jrr-jiggle-link-default";
    }

    // Function returns icon based upon string in 'icon' prop ( default is right facing chevron )
    const getIcon = () => {
        return (
            <FontAwesomeIcon icon={faChevronRight} className="jrr-jiggle-link-icon"/>
        );
    }
    
    return (
        <div id={id} onClick={onclick} className={className}>
            <p id="jrr-jiggle-link-label">{label}</p>
            {getIcon()}
        </div>
    )
}

export default JrrJiggleLink;